/**
 * Setup the global elements of the react app:
 *  - redux store
 *  - react router
 *  - main component: App
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import { initialState, oauthInitialState } from './config/initialStateSlice';
import configureAppStore from './config/store';
import * as serviceWorker from './serviceWorker';

import { createCartoSlice, createOauthCartoSlice } from '@carto/react/redux';

import { setDefaultCredentials } from '@deck.gl/carto';

export default function (element, props = {}) {
  const mapsUrl = props.mapsUrl || process.env.REACT_APP_MAPS_URL;
  const region = props.region;
  setDefaultCredentials({
    ...(mapsUrl && { mapsUrl }),
    ...(region && { region }),
  });

  const store = configureAppStore();
  store.reducerManager.add('carto', createCartoSlice(initialState));
  store.reducerManager.add('oauth', createOauthCartoSlice(oauthInitialState));

  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App {...props} />
      </BrowserRouter>
    </Provider>,
    element
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
